module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"Flashix","name":"Flashix","icon":"./assets/favicon.png","start_url":"/","background_color":"#4435B0","display":"fullscreen","theme_color":"#4435B0","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c009f4c44e42d023051158545f66df17"},
    },{
      plugin: require('../../../node_modules/@loicmahieu/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":null},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
